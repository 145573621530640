import React, { useState, useRef } from "react";
import styled from "styled-components";

const CardWrap = styled.div`
  input,
  textarea,
  select {
    font-size: 16px;
  }

  h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  input {
    height: 50px;
    width: 50px;
    padding: 0 10px;
    border: none;
    font-size: 35px;
    text-align: center;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
  button {
    display: block;
    margin: 10px auto;
    background: #7bafd4;
    color: #f6f6f6;
    font-weight: 700;
    font-size: 18px;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
`;

function Flashcard({ question, onCorrect, onIncorrect, onInputChange }) {
  const [userAnswer, setUserAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(userAnswer) === question.answer) {
      onCorrect();
    } else {
      onIncorrect();
    }
    setUserAnswer("");
  };

  const getSymbol = (operation) => {
    switch (operation) {
      case "addition":
        return "+";
      case "subtraction":
        return "-";
      case "multiplication":
        return "×";
      case "division":
        return "÷";
      default:
        return "+";
    }
  };

  const inputRef = useRef(null);

  const handleBlur = (e) => {
    // Re-focus the input whenever it blurs
    inputRef.current.focus();
  };

  const handleAnswerChange = (value) => {
    setUserAnswer(value); // Update flashcard state
    onInputChange(value); // Notify the parent
  };

  return (
    <CardWrap className="flashcard">
      <h2>
        {question.num1} {getSymbol(question.operation)} {question.num2}
      </h2>
      <form onSubmit={handleSubmit}>
        <input
          ref={inputRef}
          onBlur={handleBlur}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={userAnswer}
          onChange={(e) => handleAnswerChange(e.target.value)}
          placeholder=""
        />
        <button type="submit">Check Answer</button>
      </form>
    </CardWrap>
  );
}

export default Flashcard;
