import React from "react";
import styled from "styled-components";
import house from "../assets/images/house.png";

// Define some styled components
const Container = styled.div`
  h1 {
    color: #f0f0f0;
    font-size: 100px;
    font-weight: 600;
    text-align: center;
    font-size: 40px;
  }
`;
const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 20px;
  margin-bottom: 50px;
`;
const ProjectList = styled.div`
  ul {
    text-align: left;
    width: 400px;
    max-width: 100%;
    list-style: none;
    margin: 0 auto;

    li {
      padding: 15px 20px;
      margin: 10px 0;
      border-radius: 15px;
      color: #fff;

      a {
        text-decoration: none;
        color: #fff;
      }

      h3 {
        font-weight: 800;
        margin: 0;
        margin-bottom: 5px;
        font-size: 22px;
      }

      p {
        margin: 0;
        font-weight: 300;
      }

      &.blue {
        background: #7bafd4;
      }
      &.green {
        background: #a6b37d;
      }
      &.yellow {
        background: #ffb85c;
      }
      &.brown {
        background: #b99470;
      }
      &.wip {
        background: #808080;
      }
    }
  }
`;

function Home() {
  const projects = [
    {
      name: "Zoomies Photo",
      link: "https://zoomiesphoto.com",
      description: "Photo Website",
      color: "blue",
    },
    {
      name: "Card Games",
      link: "/cards",
      description: "Card games with reusable card component",
      color: "green",
    },
    {
      name: "Bias Tester",
      link: "/bias",
      description: "Find your blindspots",
      color: "wip",
    },
    {
      name: "Simple Reminder",
      link: "/remind",
      description: "Keep track of things to remember",
      color: "wip",
    },
    {
      name: "Flash Cards",
      link: "/math",
      description: "Math flash cards, quiz and time yourself",
      color: "wip",
    },
    {
      name: "Blank Multiplication Chart",
      link: "/times-chart",
      description: "Fill out the chart",
      color: "wip",
    },
    {
      name: "Simple Workout Planner Tracker",
      link: "/sweat",
      description: "Simple way to plan and track workouts",
      color: "wip",
    },
    {
      name: "Bullock Beliefs Cheatsheet",
      link: "/believe",
      description: "An overview of what we believe and find most important",
      color: "wip",
    },
    {
      name: '"Days Without..." Habit Tracker',
      link: "/days-without",
      description: "A Day's without accident type tracker",
      color: "wip",
    },
    {
      name: "Compliment Keeper",
      link: "/nice",
      description:
        "Save compliments you want to give others and set reminders to give them",
      color: "wip",
    },
    {
      name: "Yell tracker",
      link: "/yell",
      description: "Track how often you're yelling",
      color: "wip",
    },
  ];
  return (
    <Container>
      <Hero>
        <h1>My Projects</h1>
      </Hero>
      <ProjectList>
        <ul>
          {projects.map((project, index) => {
            return (
              <li className={`project ${project.color}`}>
                <a href={project.link}>
                  <h3>{project.name}</h3>
                  <p>{project.description}</p>
                </a>
              </li>
            );
          })}
        </ul>
      </ProjectList>
    </Container>
  );
}

export default Home;
