import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import ical from "ical.js";

function BasicCalendar() {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dailyEvents, setDailyEvents] = useState([]);
  const calURL = process.env.FAMILY_CAL_URL;

  // Fetch and parse iCal data when the component mounts
  useEffect(() => {
    async function fetchICalData() {
      try {
        const response = await fetch(
          "https://bullock.family/api/fetch-calendar"
        );
        const icalData = await response.text();

        return;

        // Parse the iCal data
        const jcalData = ical.parse(icalData);
        const comp = new ical.Component(jcalData);
        const vevents = comp.getAllSubcomponents("vevent");

        // Extract event details
        const parsedEvents = vevents.map((event) => {
          const summary = event.getFirstPropertyValue("summary");
          const startDate = event.getFirstPropertyValue("dtstart").toJSDate();
          const endDate = event.getFirstPropertyValue("dtend").toJSDate();
          return { summary, startDate, endDate };
        });

        setEvents(parsedEvents);
      } catch (error) {
        console.error("Error fetching iCal data:", error);
      }
    }

    fetchICalData();
  }, []);

  // Filter events for the selected date
  useEffect(() => {
    const filteredEvents = events.filter(
      (event) => event.startDate.toDateString() === selectedDate.toDateString()
    );
    setDailyEvents(filteredEvents);
  }, [selectedDate, events]);

  // Check if a date has events
  const hasEvents = (date) => {
    return events.some(
      (event) => event.startDate.toDateString() === date.toDateString()
    );
  };

  // Custom tile content for Calendar component
  const tileContent = ({ date, view }) => {
    // Only add dots on month view and if the date has events
    if (view === "month" && hasEvents(date)) {
      return <EventIndicator />;
    }
    return null;
  };

  return (
    <CalendarWrap>
      {/* <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileContent={tileContent} // Pass custom content for tiles
        // calendarType="US"
      /> */}
      <EventList>
        <h2>What's Happening Today </h2>
        <p className="date">{selectedDate.toDateString()}</p>
        <br />
        <br />
        {dailyEvents.length > 0 ? (
          dailyEvents.map((event, index) => (
            <Event key={index}>
              <h4>{event.summary}</h4>
              <p>
                {event.startDate.toLocaleTimeString()} -{" "}
                {event.endDate.toLocaleTimeString()}
              </p>
            </Event>
          ))
        ) : (
          <p>No events for this date.</p>
        )}
      </EventList>
    </CalendarWrap>
  );
}

export default BasicCalendar;

// Styled Components

const CalendarWrap = styled.div`
  display: grid;

  h2 {
    font-size: 30px;
    margin: 0 0 10px 0;
  }

  .date {
    margin: 0;
    color: #64a4f4;
    font-weight: 700;
  }

  .react-calendar {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
    font-family: Arial, sans-serif;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #f7f7f7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .react-calendar__navigation button {
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      color: #0077cc;
    }
  }
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    font-weight: bold;
    text-align: center;
    color: #0077cc;
  }

  .react-calendar__tile {
    flex: 1 !important;
    padding: 10px;
    margin: 10px;
    background-color: #f0f4f8;
    border-radius: 6px;
    border: 1px solid #f6f6f6;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e3eaf3;
    }

    &--active {
      background-color: #0077cc;
      color: #fff;
    }
  }

  .react-calendar__tile--now {
    background-color: #ffefc3;
    border-radius: 6px;
  }

  .react-calendar__tile--hasActive,
  .react-calendar__tile--now:hover {
    background-color: #ffe29b;
  }
`;

const EventIndicator = styled.div`
  width: 6px;
  height: 6px;
  background-color: #0077cc;
  border-radius: 50%;
  margin: 0 auto;
`;

const EventList = styled.div`
  width: 100%;
  max-width: 400px;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

const Event = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  h4 {
    margin: 0;
    font-size: 1rem;
    color: #333;
  }

  p {
    font-size: 0.9rem;
    color: #666;
    margin-top: 4px;
  }
`;
